import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';

const Wrapper = styled.button`
  ${fontstack.default}
  ${type('caption')}
  color: #193D3D;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  text-transform: uppercase;
  border-bottom: 1px solid #1D1D1D;
  display: inline-block;
`

const CallToAction = (props:any) => {
  const { children } = props;
  return (
    <Wrapper>
      { children }
    </Wrapper>
  )
}

export default CallToAction;