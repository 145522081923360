import React from 'react';
import { v4 as uuidv4 }from "uuid";
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin: 0 0 20px; 

  ${media.medium`
    margin: 0 0 40px; 
  `}
`
const DetailLine = styled.div`
  ${fontstack.default}
  position: relative;
`

const DetailProperty = styled.div`
  ${fontstack.default}   
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  ${media.medium`
    ${type('body')}
    font-weight: 500;
  `}
`

const DetailValue= styled.div` 
  display: inline-block;
  ${fontstack.default}   
  font-size: 14px;
  margin: 0 0 0 5px;
  ${media.medium`
    ${type('body')}
  `}
`

const DetailColumn = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-right: 10px;
  }
`

const Details = (props:any) => {
  const { data } = props;

  return (
    <Wrapper>
      <DetailColumn key={uuidv4()}>
      {data ? 
        data.map(line => {
          return (
            <DetailProperty>{line.property}:</DetailProperty>
          )
        })
      : null}
      </DetailColumn>
      <DetailColumn key={uuidv4()}>
      {data ? 
        data.map(line => {
          return (
            <DetailValue>{line.value}</DetailValue>
          )
        })
      : null}
      </DetailColumn>
    </Wrapper>
  )
}

export default Details;