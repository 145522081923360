import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from "swiper/react";
import LazyImage from '../LazyImage/LazyImage';
import Arrow from './assets/Arrow.svg';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

// import Swiper core and required modules
import SwiperCore, {
  EffectFade,Pagination,Navigation,Autoplay
} from 'swiper';

SwiperCore.use([EffectFade,Pagination,Navigation,Autoplay]);

import 'swiper/css';
import "swiper/css/pagination"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`

const Item = styled.div`
  width: 100%;
`

const Image = styled.img`
  display: block;
  position: relative;
  width: 100%;
`

const PaginationWrapper = styled.div`
  ${fontstack.default}
  ${type('caption')}
  position: absolute;
  bottom: 25px;
  left: 25px;
  color: white;
  z-index: 2;
  user-select: none;

  ${media.medium`
    ${type('body')}
    left: 40px;
    bottom: 50px;
  `}
`
const CaptionWrapper = styled.div`
  ${fontstack.default}
  ${type('caption')}
  position: absolute;
  bottom: 25px;
  width: 100%;
  color: white;
  z-index: 2;
  text-align: center;
  user-select: none;

  ${media.medium`
    ${type('body')}
    bottom: 50px;
  `}
`

const ArrowWrapper = styled.div<{show?:boolean}>`
  display: flex;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;
  align-items: center;
  box-sizing: border-box;
  padding: 0 25px;
  justify-content: space-between;
  pointer-events: none;
  transition: opacity .2s ease;

  ${media.medium`
    padding: 0 40px;
    opacity: ${props => props.show ? '1' : '0' };
  `}
`

const ArrowIcon = styled(Arrow)`
  width: 14px;
  fill: #fff;

  ${media.medium`
    width: 25px;
  `}
`

const ArrowInversed = styled(ArrowIcon)`
  transform: rotate(180deg);
`

const PrevWrapper = styled.div` 
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  padding-right: 20px;
  cursor: pointer;
  pointer-events: all;
`

const NextWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  padding-left: 20px;
  cursor: pointer;
  pointer-events: all;
`

const Carousel = (props:any) => {
  const { data, pagination, autoplay, show } = props;
  const [ Caption, setCaption ] = useState(0);
  const [ ShowArrows, setShowArrows ] = useState(false);
  const [ Loaded, setLoaded ] = useState(false);

  const prevRef = useRef();
  const nextRef = useRef();
  const paginationRef = useRef();
  const swiperRef = useRef(null);

  useEffect(() => {
    if(swiperRef.current && autoplay && !show) {
      swiperRef.current.swiper.autoplay.stop();
    } else if(swiperRef.current && autoplay && show) {
      swiperRef.current.swiper.autoplay.start();
    }
    
    setLoaded(true);

  },[Loaded, show]);


  const slideChange = (swiperCore) => {
      const {
        activeIndex,
        previousIndex,
        realIndex,
      } = swiperCore;
      setCaption(realIndex);
  }

  return (
    <Wrapper onMouseEnter={()=>setShowArrows(true)} onMouseLeave={()=>setShowArrows(false)}>
      <ArrowWrapper show={ShowArrows}>
        <PrevWrapper ref={prevRef} >
          <ArrowInversed  />
        </PrevWrapper>
        <NextWrapper ref={nextRef}>
          <ArrowIcon />
        </NextWrapper>
      </ArrowWrapper>
      <CaptionWrapper>{data ? data[Caption].caption : null }</CaptionWrapper>
      { pagination ? <PaginationWrapper ref={paginationRef}></PaginationWrapper> : null }
      { Loaded ? <Swiper 
        // @ts-ignore
        ref={swiperRef}
        onSlideChange={slideChange}
        pagination={{
          "type": "fraction",
          "el": pagination ? paginationRef.current : null 
        }}
        navigation={{
          nextEl: nextRef.current,
          prevEl: prevRef.current,
        }}
        effect={"fade"}
        loop={true} 
        autoplay={autoplay}
        >
        {data ? data.map((object) => {
          return (
            <SwiperSlide>
              <Item>
                <LazyImage {...object.image}/>
              </Item>
            </SwiperSlide>
          )
        })
        : null}
      </Swiper> : null}
    </Wrapper>
  )
}

export default Carousel;