import React, { useEffect, useState, useRef, forwardRef } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Section from '../Section/Section';
import LazyImage from '../LazyImage/LazyImage';
import { HeroStatement, LargeHeader } from '../TextComponents/TextComponents';
import { CopyA, CopyB, CopyC } from '../CopyModule/CopyModule';
import Carousel from '../Carousel/Carousel';
import Map from './assets/The Backdrop_image 2_map graphic@2x.png';
import { media } from '../../utils/mediaQuery';
import useWindowSize from '../../utils/useWindowSize';

const CentredSection = styled(Section)<{animated?:boolean, show?:boolean}>`
  text-align: left;
  margin-top: 40px;
  margin-bottom: 40px;

  ${media.medium`
    text-align: center;
    margin-top: 145px;
    margin-bottom: 145px;
  `}

  ${({ animated }) => animated && `
    transform: translate(0, 100px);
    opacity: 0;
    transition: all .8s ease .4s; 
  `}

  ${({ show }) => show && `
    transform: translate(0, 0px);
    opacity: 1;
  `}
`

const Image = styled.img` 
  position: relative;
  width: 100%;
`

const AnimateWrapper = styled.div<{animated?:boolean, show?:boolean, delay?:number}>` 

${({ animated, delay }) => animated && `
  transform: translate(0, 100px);
  opacity: 0;
  transition: all .8s ease ${delay || 0}s; 
`}

${({ show }) => show && `
  transform: translate(0, 0px);
  opacity: 1;
`}

`

const ImageWrapper = styled.div<{animated?:boolean, show?:boolean}>` 

  ${({ animated }) => animated && `
    transform: translate(0, 100px);
    opacity: 0;
    transition: all .8s ease .4s; 
  `}

  ${({ show }) => show && `
    transform: translate(0, 0px);
    opacity: 1;
  `}

`

const CopyWrapper = styled.div<{animated?:boolean, show?:boolean}>` 

  ${({ animated }) => animated && `
    transform: translate(0, 100px);
    opacity: 0;
    transition: all .8s ease .8s; 
  `}

  ${({ show }) => show && `
    transform: translate(0, 0px);
    opacity: 1;
  `}

`

const Grid = styled.div<{ reverse?: boolean, rowGap?:number }>` 
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: ${props => props.rowGap ? `${props.rowGap}px` : '96px'};
  grid-auto-flow: ${props => props.reverse ? 'dense' : 'row'};
`

const BlockHeaderWrapper = styled.div` 
  grid-column: 2 / span 10;
`

const BlockHeaderMod = styled(LargeHeader)`
margin-bottom: 0; 
`

const BlockHeader = (props:any) => {
  const { children, animated, show } = props;
  return (
    <CentredSection contain none>
      <Grid>
        <BlockHeaderWrapper>
          <AnimateWrapper animated={animated} show={show} delay={.4}>
            <BlockHeaderMod>{children}</BlockHeaderMod>
          </AnimateWrapper>
        </BlockHeaderWrapper>
      </Grid>
    </CentredSection>
  )
}

const HeroStatementWrapper = styled.div` 
  grid-column: 2 / span 10;
  ${media.medium`
    grid-column: 1 / span 12;
  `}
`

const BlockQuoteSection = styled(Section)`
  text-align: center;
  margin-top: 252px;
  margin-bottom: 200px;
  ${media.medium`
    margin-top: 435px;
    margin-bottom: 256px;
  `}
`

const BlockQuote = (props:any) => {
  const { children, animated, show, ...rest } = props;
  return (
    <BlockQuoteSection large {...rest}>
      <Grid>
        <HeroStatementWrapper>
          <AnimateWrapper animated={animated} show={show}>
            <HeroStatement>
              { children }
            </HeroStatement>
          </AnimateWrapper>
        </HeroStatementWrapper>
      </Grid>
    </BlockQuoteSection>
  )
}

const BlockAImageWrapper = styled(ImageWrapper)` 
  grid-column: 1 / span 10;
  grid-row: 2;

  ${media.medium`
    grid-column: 1 / span 6;
    grid-row: 1;
  `}
`

const BlockACopyWrapper = styled(CopyWrapper)`
  grid-column: 3 / span 9;

  ${media.medium`
    grid-column: 8 / span 4;
  `}
`

const BlockA = (props:any) => {
  const { image, title, callToAction, html, animated, show} = props;
  const [ width, height ] = useWindowSize();
  return (
    <Section large contain>
      <Grid>
        <BlockAImageWrapper animated={animated} show={show}>
          <LazyImage {...image}/>
        </BlockAImageWrapper>
        <BlockACopyWrapper animated={animated} show={show}>
          <CopyA title={title} callToAction={callToAction} shift={width > 900 ? true : false}>
            {html}
          </CopyA>
        </BlockACopyWrapper>
      </Grid>
    </Section>
  )
}

const BlockB = (props:any) => {
  const { image, animated, show } = props;
  return (
    <Section large full>
      <ImageWrapper animated={animated} show={show}>
        <LazyImage {...image}/>
      </ImageWrapper>
    </Section>
  )
}

const BlockBCarousel = (props:any) => {
  const { animated, show, image } = props;
  return (
    <Section large full>
      <AnimateWrapper animated={animated} show={show} delay={.4}>
        <Carousel show={show} {...props}/>
      </AnimateWrapper> 
    </Section>
  )
}

const BlockBCarouselCopyCarouselWrapper = styled.div`
  grid-column: 1 / span 12;
`
const BlockBCarouselCopyCopyWrapper = styled.div`
  grid-column: 2 / span 10;
  grid-row: 2;
`
const BlockBCarouselCopy = (props:any) => {
  const { animated, show, image, title, callToAction, html, details, ...rest } = props;
  return (
    <Section large contain>
      <Grid rowGap={40}>
        <BlockBCarouselCopyCarouselWrapper>
          <AnimateWrapper animated={animated} show={show} delay={.4}>
            <Carousel show={show} data={image} pagination {...rest}/>
          </AnimateWrapper> 
        </BlockBCarouselCopyCarouselWrapper>
        <BlockBCarouselCopyCopyWrapper>
          <AnimateWrapper animated={animated} show={show} delay={.8}>
            <CopyC details={details} callToAction={callToAction} title={title}>{html}</CopyC>
          </AnimateWrapper> 
        </BlockBCarouselCopyCopyWrapper>
      </Grid>
    </Section>
  )
}

const BlockC1ImageWrapper = styled(ImageWrapper)<{ reverse?: boolean }>` 
  grid-column: 2 / span 10;
  grid-row: 1;
  ${media.medium`
    grid-column: ${props => props.reverse ? '7 / span 5' : '2 / span 5'};
  `}
`
const BlockC1CopyWrapper = styled(CopyWrapper)<{ reverse?: boolean }>`
  grid-column: 2 / span 10;
  display: flex;
  align-items: center;
  ${media.medium`
    grid-column: ${props => props.reverse ? '2 / span 4' : '8 / span 4'};
  `}
`
const BlockC1 = (props:any) => {
  const { image, title, callToAction, html, reverse, animated, show, ...rest } = props;
  return (
    <Section contain large {...rest}>
      <Grid reverse={reverse} rowGap={40}>
        <BlockC1ImageWrapper reverse={reverse} animated={animated} show={show}>
          <LazyImage {...image}/>
        </BlockC1ImageWrapper>
        <BlockC1CopyWrapper reverse={reverse} animated={animated} show={show}>
          <CopyB title={title} callToAction={callToAction}>
            {html}
          </CopyB>
        </BlockC1CopyWrapper>
      </Grid>
    </Section>
  )
}


const BlockC2ImageWrapper = styled(ImageWrapper)<{ reverse?: boolean }>` 
  grid-column: ${props => props.reverse ? '1 / span 7' : '5 / span 8'};
  ${media.medium`
    grid-column: ${props => props.reverse ? '1 / span 6' : '7 / span 6'};
  `}
`
const BlockC2CopyWrapper = styled(CopyWrapper)<{ reverse?: boolean }>`
  grid-column: ${props => props.reverse ? '1 / span 7' : '5 / span 7'};
  display: flex;
  align-items: center;
  grid-row: 2;

  ${media.medium`
    grid-row: 1;
    grid-column: ${props => props.reverse ? '8 / span 4' : '2 / span 4'};
  `}
`

const BlockC2 = (props:any) => {
  const { image, title, callToAction, html, reverse, animated, show} = props;
  return (
    <Section large contain>
      <Grid reverse={reverse} rowGap={40}>
        <BlockC2CopyWrapper reverse={reverse} animated={animated} show={show}>
          <CopyB title={title} callToAction={callToAction}>
            {html}
          </CopyB>
        </BlockC2CopyWrapper>
        <BlockC2ImageWrapper reverse={reverse} animated={animated} show={show}>
          <LazyImage {...image}/>
        </BlockC2ImageWrapper>
      </Grid>
    </Section>
  )
}

const BlockDImageWrapper = styled(ImageWrapper)<{ reverse?: boolean }>` 
  grid-column:${props => props.reverse ?  '2 / span 7' : '5 / span 7'} ;
  ${media.medium`
    grid-column: ${props => props.reverse ?  '8 / span 5' : '1 / span 5'};
  `}
`
const BlockDCopyWrapper = styled(CopyWrapper)<{ reverse?: boolean }>`
  grid-column:${props => props.reverse ?  '2 / span 7' : '5 / span 7'} ;
  display: flex;
  align-items: center;
  ${media.medium`
    grid-column: ${props => props.reverse ? '2 / span 4' : '8 / span 4'};
  `}
`

const BlockD = (props:any) => {
  const { image, title, callToAction, html, details, reverse, animated, show, ...rest } = props;
  return (
    <Section large contain {...rest}>
      <Grid reverse={reverse} rowGap={40}>   
        <BlockDImageWrapper reverse={reverse} animated={animated} show={show}>
          { Array.isArray(image) ? <Carousel data={image}/> : <LazyImage {...image}/> }
        </BlockDImageWrapper>
        <BlockDCopyWrapper reverse={reverse} animated={animated} show={show}>
          {details ? 
          <CopyC details={details} callToAction={callToAction}>{html}</CopyC>
        : <CopyB title={title} callToAction={callToAction}>{html}</CopyB> }
        </BlockDCopyWrapper>
      </Grid>
    </Section>
  )
}

const BlockEGrid = styled.div`
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 96px;

  ${media.medium`
    grid-template-columns: repeat(3, 1fr);
  `}
`

const BlockEColumn = styled.div`
  box-sizing: border-box;
  grid-column: 2 / span 10;
  
  ${media.medium`
    grid-column: auto;
    padding: 0 40px 0;
  `}
`

const BlockE = (props:any) => {
  const { data, animated, show } = props;
  return (
    <Section large contain>
      <BlockEGrid> 
        {data ? data.map((content, index) => {
          const title = content.title || null;
          const call = content.callToAction || null;
          const html = content.html || null;
          const delay = .4 * (index+1);
          return (
            <BlockEColumn>
              <AnimateWrapper animated={animated} show={show} delay={delay}>
                <CopyB title={title} callToAction={call}>{html}</CopyB> 
              </AnimateWrapper> 
            </BlockEColumn> 
          )
        }) : null}
      </BlockEGrid>
    </Section>
  )
}

const BlockFGrid = styled(Grid)`
  position: relative;
  width: 100%;
  margin: 0 0 -56px;

  ${media.medium`
    position: absolute;
    margin: 0;
  `}
`

const BlockFHeader = styled.div` 
  grid-column: 2 / span 10;

  ${media.medium`
    grid-column: 1 / span 5;
  `}
`

const BlockF = (props:any) => {
  const { title, data, animated, show } = props;
  const [ Shift, setShift ] = useState(0);
  const headRef = useRef<HTMLHeadingElement>(null);
  const [ width, height ] = useWindowSize();

  useEffect(() => {
    if(headRef.current && width > 900) {
      setShift(0-headRef.current.getBoundingClientRect().height);
    } else {
      setShift(0);
    }
  }, [width])

  return (
    <Section large contain>
      <BlockFGrid>
        <BlockFHeader style={{transform:`translate(0,${Shift}px)`}}>
          <AnimateWrapper animated={animated} show={show} delay={.4}>
            <LargeHeader ref={headRef}>{title}</LargeHeader>
          </AnimateWrapper>
        </BlockFHeader>
      </BlockFGrid>
    { data ? data.map((content) => {
      const { image, title, html, callToAction } = content;
      return (
        <BlockC1 animated={animated} show={show} image={image} title={title} html={html} callToAction={callToAction} small reverse/>
      )
    }) : null}
    </Section>
  )
}

const BlockGGrid = styled(Grid)` 
  position: relative;
  margin: 0 0 -96px;
  ${media.medium`
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    margin: 0;
  `}
`

const BlockGOverFlow = styled.div` 
  position: relative;
  
  ${media.medium`
    overflow: hidden;
  `}
`

const BlockGWrapper = styled.div` 
  position: relative;
  z-index: 500;
`
const BlockGMapWrapper = styled.div`
  position: relative;
  grid-column: 2 / span 9;

  ${media.medium`
    grid-column: 2 / span 5;
  `}
`

const BlockGCopyWrapper = styled(CopyWrapper)`
  grid-column: 4 / span 8;
  ${media.medium`
    grid-column: 7 / span 4;
  `}
`

const BlockGMap = styled(Image)`
  transform: translate(0, -12%);
`
const BlockG = (props:any) => {
  const { title, html, animated, show } = props;
  return (
    <BlockGWrapper>
      <BlockGGrid>
        <BlockGMapWrapper>
          <AnimateWrapper animated={animated} show={show} delay={.4}>
            <BlockGMap src={Map} alt="map"/>
          </AnimateWrapper>
        </BlockGMapWrapper>
      </BlockGGrid>
      <BlockGOverFlow>
        <Section full large>
          <Grid>
            <BlockGCopyWrapper animated={animated} show={show}>
              <CopyA title={title}>
                {html}
              </CopyA>
            </BlockGCopyWrapper>
          </Grid>
        </Section>
      </BlockGOverFlow>
    </BlockGWrapper>
  )
}

const BlockHImageWrapper = styled.div` 
  grid-column: 1 / span 3;
`

const BlockH = (props:any) => {
  const { image, animated, show } = props;
  return (
    <Section none contain>
      <Grid>   
        <BlockHImageWrapper>
          <AnimateWrapper animated={animated} show={show} delay={.4}>
            <LazyImage {...image}/>
          </AnimateWrapper>
        </BlockHImageWrapper>
      </Grid>
    </Section>
  )
}


const BlockIBottomGrid = styled(Grid)`
  margin: 40px 0 0;

  ${media.medium`
    margin: 256px 0 0;
  `}
`
const BlockIImageOneWrapper = styled.div` 
  grid-column: 1 / span 8;
  ${media.medium`
    grid-column: 4 / span 4;
  `}
`

const BlockIImageTwoWrapper = styled.div` 
  grid-column: 9 / span 4;
  grid-row: 2;
  ${media.medium`
    grid-column: 10 / span 3;
  `}
`

const BlockIImageThreeWrapper = styled.div` 
  grid-column: 2 / span 10;
  ${media.medium`
    grid-column: 1 / span 5;
  `}
`

const BlockIDetached = styled.div` 
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  left: 0; 
  top: 0;
  align-items: center;
`

const BlockIDetachedGrid = styled(Grid)` 
  width: 100%;
  margin: 80px 0 0;
  ${media.medium`
    grid-column: 10 / span 3;
    margin: 0;
  `}
`

const BlockI = (props:any) => {
  const { images, animated, show } = props;
  const [ width, height ] = useWindowSize();

  return (
    <Section large contain>
      <Grid rowGap={width <= 900 ? 1 : null}>
        <BlockIImageOneWrapper>
          <AnimateWrapper animated={animated} show={show} delay={.12}>
            <LazyImage {...images[0]}/>
          </AnimateWrapper>
        </BlockIImageOneWrapper>
        { width <= 900 ? <BlockIImageTwoWrapper>
          <AnimateWrapper animated={animated} show={show} delay={.60}>
            <LazyImage {...images[1]}/>
          </AnimateWrapper>   
        </BlockIImageTwoWrapper> : null }
      </Grid>
      <BlockIBottomGrid>
      <BlockIImageThreeWrapper>
        <AnimateWrapper animated={animated} show={show} delay={1.2}>
            <LazyImage {...images[2]}/>
        </AnimateWrapper>
      </BlockIImageThreeWrapper>
      </BlockIBottomGrid>
      { width > 900 ?
      <BlockIDetached>
        <BlockIDetachedGrid>
          <BlockIImageTwoWrapper>
          <AnimateWrapper animated={animated} show={show} delay={.60}>
            <LazyImage {...images[1]}/>
          </AnimateWrapper>   
          </BlockIImageTwoWrapper>
        </BlockIDetachedGrid>
      </BlockIDetached> : null 
      }
    </Section>
  )
}

const BlockVRWrapper = styled.div` 
  width: 100%;
  height: 400px;
  position: relative;

  ${media.medium`
    height: 916px;
  `}
`

const BlockVRFrame = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`
const BlockVRCopySection = styled(Section)`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;

  ${media.medium`
    margin-top: 145px;
    margin-bottom: 145px;
  `}
`

const BlockVR = (props:any) => {
  const { src, copy, animated, show } = props;
  return (
    <>
    <Section large contain>
    <BlockVRCopySection contain none>
      <AnimateWrapper animated={animated} show={show} delay={.4}>
        <BlockHeaderMod>{copy}</BlockHeaderMod>
      </AnimateWrapper>
    </BlockVRCopySection>
    <AnimateWrapper animated={animated} show={show} delay={.10}>
      <BlockVRWrapper>
          <BlockVRFrame 
          className="theViewerEmbeddedClass" 
          src={src} 
          allow="accelerometer; encrypted-media; gyroscope;" 
          allowFullScreen={true} />
      </BlockVRWrapper>
      </AnimateWrapper>
    </Section>
    </>
  )
}

const BlockVideoTag = styled.video`
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`

const BlockVideo = (props:any) => {
  const { src, copy, animated, show } = props;
  return (
    <>
    <Section large contain>
    { copy && <BlockVRCopySection contain none>
      <AnimateWrapper animated={animated} show={show} delay={.4}>
        <BlockHeaderMod>{copy}</BlockHeaderMod>
      </AnimateWrapper>
    </BlockVRCopySection> }
    <AnimateWrapper animated={animated} show={show} delay={.10}>
      <BlockVRWrapper>
        <BlockVideoTag playsInline controls>
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </BlockVideoTag>
      </BlockVRWrapper>
      </AnimateWrapper>
    </Section>
    </>
  )
}

export {
  BlockHeader,
  BlockQuote,
  BlockA,
  BlockB,
  BlockBCarousel,
  BlockBCarouselCopy,
  BlockC1,
  BlockC2,
  BlockD,
  BlockE,
  BlockF,
  BlockG,
  BlockH,
  BlockI,
  BlockVR,
  BlockVideo,
}
