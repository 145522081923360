import React from 'react';
import styled from 'styled-components';
import { LazyBlur } from '@manualengineering/react-lazyblur';

const Image = styled.img`
  width: 100%;
`

const LazyImage = (props:any) => {
  const { hash,placeholder,width,height,src,alt } = props;
  return (
    <LazyBlur 
      offset={3000}
      blurhash={{
        src: null,
        hash: hash || null
      }}
      placeholder={placeholder || null}
      placeholderWidth={width} 
      placeholderHeight={height}>
        <Image src={`${src}&q=20`} alt={alt}/> 
    </LazyBlur>
  )
}

export default LazyImage;