import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import HtmlParser from '../HtmlParser/HtmlParser';
import LinkFade from '../LinkFade/LinkFade';
import CallToAction from '../CallToAction/CallToAction';
import { LargeHeader, MediumHeader } from '../TextComponents/TextComponents';
import Details from '../Details/Details';
import { media } from '../../utils/mediaQuery';

type Props = {
  title: string,
  callToAction?: any,
  children: React.ReactNode,
  shift?:boolean
}

const Wrapper = styled.div` 
  position: relative;
`

const CallWrapper = styled.div` 
  margin: 40px 0 0;
  ${media.medium`
    margin: 50px 0 0;
  `}
`
const NavLink = styled(LinkFade)`
  text-decoration: none;
  color: inherit;
  z-index: 1;
`

const Copy = (props:any) => {
  const { children, callToAction, html, ...rest } = props;

  return (
  <Wrapper {...rest}>
      { children }
      <HtmlParser>
        {html}
      </HtmlParser>
      { callToAction ?
      <CallWrapper>
        <NavLink to={callToAction.href && callToAction.href.startsWith('/') ? callToAction.href : null } href={callToAction.href && !callToAction.href.startsWith('/') ? callToAction.href : null }><CallToAction>{callToAction.label}</CallToAction></NavLink>
      </CallWrapper> : null}
  </Wrapper>
  )
}

const CopyA = (props:Props) => {
  const { children, title, callToAction, shift} = props;
  const [ Shift, setShift ] = useState(0);
  const headRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if(headRef.current && shift) {
      setShift(0-headRef.current.getBoundingClientRect().height);
    } else {
      setShift(0);
    }
  }, [shift])
  return (
    <Copy html={children} callToAction={callToAction} style={{transform:`translate(0,${Shift}px)`}}>
      <LargeHeader ref={headRef}>{title}</LargeHeader>
    </Copy>
  )
}

const CopyB = (props:any) => {
  const { children, title, callToAction } = props;
  
  return (
    <Copy html={children} callToAction={callToAction}>
      {title ? <MediumHeader>{title}</MediumHeader> : null }
    </Copy>
  )
}

const CopyC = (props:any) => {
  const { children, title, details, callToAction } = props;
  
  return (
    <Copy html={children} callToAction={callToAction}>
      {title ? <MediumHeader>{title}</MediumHeader> : null }
      <Details data={details} />
    </Copy>
  )
}

export { 
  CopyA,
  CopyB,
  CopyC
};